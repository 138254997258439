import styled from "styled-components"

export const StatusStyled = styled.div`
  div.status {
    display: ${props => (props.showStatus ? "flex" : "none")};
    color: ${props => (props.officeOpen ? "#94C835" : "red")};
    color: #94c835;
    border: ${props =>
    props.officeOpen ? "1px solid #94C835" : "1px solid red"};
    border: 1px solid #94c835;
    border-radius: 4px;
    padding: 2px 10px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 15px 0;
    width: fit-content;
  }
  @media only screen and (min-width: 768px) {
    div.l-mt-51 {
      margin: 51px 0 15px 0;
    }
  }
`