import React, { useEffect, useState } from "react";
import { injectIntl } from "gatsby-plugin-intl";
import { StatusStyled } from "./statusStyled";

const Status = ({ from, to, className, intl }) => {

  const NewOfficeStatus = (openFrom, openTill) => {
    let time = new Date().toLocaleTimeString()
    let fullHour = time.toString().replace(':', '').slice(0, -3);
    console.log('Full Hour', fullHour);
    let currentHour = time.toString().split(":")[0]
    let currentDay = new Date()
      .toString()
      .split(" ")[0]
      .toLowerCase()
    console.log('current hour', currentHour)
    console.log('current day', currentDay)
    if (currentDay !== "sat" || currentDay !== "sun") {
      return openFrom <= fullHour && fullHour < openTill && true
    }
  }
  const [officeOpen] = useState(NewOfficeStatus(from, to))
  console.log('office Open ?', officeOpen)
  useEffect(() => {
    return () => {
      console.log("SET TO FALSE")
      localStorage.setItem("id", false)
    }
  }, [])
  return (
    <StatusStyled
      showStatus={true}
    >
      <div className={'status ' + className}>
        {officeOpen
          ? intl.formatMessage({ id: "office.open" })
          : intl.formatMessage({ id: "office.closed" })}
      </div>
    </StatusStyled>
  )
}

export default injectIntl(Status);
